import request from '../utils/request'
import baseUrl from './baseUrl'


export const integralSet = (data) => request({
  url: baseUrl + '/integralSet/select',
  method: 'POST',
  data
})
//积分规则设置
export const integralSetSave = (data) => request({
  url: baseUrl + '/integralSet/modify',
  method: 'POST',
  data
})