<template>
  <div class="integralSetting">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>积分设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="分享积分:">
        <el-input
          v-model="formList.shareIntegral"
          placeholder=""
          @input="onInput"
        ></el-input>
        <span style="color: red; margin-left: 10px">分享赠送的积分</span>
      </el-form-item>
      <el-form-item label="每日分享最高积分:">
        <el-input
          v-model="formList.maxShareIntegral"
          placeholder=""
          @input="onInput"
        ></el-input>
        <span style="color: red; margin-left: 10px"
          >单个会员每天分享领取的积分上限</span
        >
      </el-form-item>
      <el-form-item label="	消费积分:">
        <span style="color: red; margin-right: 10px">消费</span>
        <el-input
          v-model="formList.shoppingIntegral"
          @input="onInput"
          placeholder=""
        ></el-input>
        <span style="color: red; margin-left: 10px">积分,抵扣一元</span>
      </el-form-item>
      <el-form-item label="	购物积分:">
        <span style="color: red; margin-right: 10px">购物满</span>

        <el-input
          v-model="formList.priceIntegral"
          @input="onInput"
          placeholder=""
        ></el-input>
        <span style="color: red; margin-left: 10px">元,获得一积分</span>
      </el-form-item>
      <el-form-item label="	评论积分:">
        <el-input
          v-model="formList.evalIntegral"
          @input="onInput"
          placeholder=""
        ></el-input>
        <span style="color: red; margin-left: 10px">评论一次,获得积分</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { integralSet, integralSetSave } from "../../api/integralSet";
export default {
  name: "integralSetting",
  data() {
    return {
      formList: {
        shareIntegral: "",
        maxShareIntegral: "",
        shoppingIntegral: "",
        priceIntegral: "",
        evalIntegral: "",

      },
    };
  },
  created() {
    this.getintegralSet();
  },
  methods: {
    onInput(value) {
      var reg = /^\+?[1-9][0-9]*$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正整数",
        });
      }
      console.log(value);
    },
    async getintegralSet() {
      const { data } = await integralSet();
      if(data.code == 0){
        if(data.data[0]){
          this.formList = data.data[0];
        }
      }
      console.log(data);
    },
    async onSave() {
      var reg = /^\+?[1-9][0-9]*$/;
      for (const key in this.formList) {
        if (!this.formList[key]) {
          return this.$message({
            type: "error",
            message: "请把数据填写完整！",
          });
        } else if (!reg.test(this.formList[key])) {
          return this.$message({
            type: "error",
            message: "请输入正整数",
          });
        }
      }
      const { data } = await integralSetSave(this.formList);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: "保存成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "保存失败",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.integralSetting {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.el-form {
    margin-top: 50px;

    .el-form-item {
      display: flex;
      .el-form-item__label {
        width: 150px !important;
      }
      .el-form-item__content {
        width: 600px;
        .el-input {
          width: 300px;
        }
      }
    }
  }
  .el-button {
    margin-left: 250px;
  }
}
</style>